@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import custom font */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');


@layer utilities {
  /* Custom utility classes for secondary text color and z-index */
  .text-secondary {
    @apply text-[theme('colors.secondary')]; /* Utilizing your theme colors */

  }
  .z-index-1000 {
    @apply z-50; /* Adjusted to Tailwind's scale if 1000 is beyond it */
  }

  /* Custom utility class for custom height */
  .h-custom {
    height: calc(100vh - 6rem); /* Adjust 25px as needed */
  }
  /* Custom utility class for custom height */
  .h-custom-double {
    height: calc(2*(100vh - 6rem)); /* Adjust 25px as needed */
  }
  
  /* Custom utility class for custom height */
  .h-custom-lg {
    height: calc(100vh - 12rem); /* Adjust 25px as needed */
  }

}

@layer components {

  .text-default{

    @apply font-normal font-sans
  }

  .text-default-alternative{

    @apply font-normal font-sans p-1 text-white
  }



  /* Custom Button Component */
  .btn {
    @apply font-sans border-solid bg-transparent text-base px-2 py-2 rounded-none border-[theme('borderWidth.borderSize')] border-[theme('colors.secondary')];
    &:hover {
      @apply text-white bg-black cursor-pointer;
    }
  }
  
  /* Custom Button Component */
  .btn-symbol {
    @apply font-sans border-solid bg-transparent text-base px-2 py-2 rounded-none;
    &:hover {
      @apply text-white bg-black cursor-pointer;
    }
  }
  
/* Custom Button Component */
.btn-negative {
  @apply font-sans border-solid text-base px-2 py-2 rounded-none  bg-black text-white;
  &:hover {
    @apply text-black bg-white cursor-pointer border-black;
  }
}

/* Custom Button Component */
.btn-negative-tranparant {
  @apply font-sans border-solid text-base px-2 py-2 rounded-none border-white border-[theme('borderWidth.borderSize')]  text-white;
  &:hover {
    @apply text-black bg-white cursor-pointer border-black;
  }
}


  /* Custom Icon Component */
  .custom-icon {
    @apply w-10 h-10 opacity-50 bg-transparent p-1 flex items-center justify-center;
    &:hover {
      @apply cursor-pointer opacity-100;
    }
  }


.nav-bottom {
@apply border-secondary;
border-bottom-width: theme('borderWidth.borderSize');

}

.nav-top {
@apply border-secondary;
border-top-width: theme('borderWidth.borderSize');

}
.nav-top-hidden {
@apply border-secondary;
border-top-width: theme('borderWidth.borderSize');
border-width: 0

}

.nav-right {
  @apply border-secondary;
  border-right-width: theme('borderWidth.borderSize');
  
  }

.nav-right-alternative {
  @apply border-third;
  border-right-width: theme('borderWidth.borderSize');
  
  }

.nav-standard{
  @apply border-secondary;
  border-right-width: theme('borderWidth.borderSize');
  border-bottom-width: theme('borderWidth.borderSize');
}

.nav-left{
  @apply border-secondary;
  border-left-width: theme('borderWidth.borderSize');
}

.nav{
  @apply border-secondary;
  border-width: theme('borderWidth.borderSize');
}

.nav-left-alternative{
  @apply border-third;
  border-left-width: theme('borderWidth.borderSize');
}

.dropdown{
  @apply border-secondary;
  border-width: theme('borderWidth.borderSize');
}

  /* Title Text with Hover Effects */
  .titel-text {
    @apply font-sans font-light text-2xl relative transition-opacity duration-300;
    &:hover {
      @apply opacity-50;
    }
  }

  /* List Items Including Last Element Customization */
  .list-item {
    @apply text-xl relative p-2 ;
    
    &:hover {
      @apply bg-black text-white;
    }

  }
  
 



  .list-group {
    @apply absolute border border-solid border-black bg-transparent text-base rounded-none bg-white z-50 border-[theme('borderWidth.borderSize')];
    > *:not(:last-child) { /* Adding separation between items */
      @apply border-b border-solid border-black border-b-[theme('borderWidth.borderSize')];
    }
  }

  .HeaderTextColumn {
    @apply flex justify-start items-center;
    /* Added hover styles */
    

  }
  .HeaderTextColumnHoover {
    &:hover {
      @apply bg-black text-white; /* Adjust background color as desired */
    }

  }

  .unselectable {
    user-select: none; /* Standard syntax */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    
}


  

  




}